.wp-block-cover__inner-container {
  max-width: 1200px;
  // max-width: unset !important;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wp-block-cover h4 {
  color: #fff;
  font-weight: 600 !important;
  font-family: Brandon Grotesque !important;
  text-align: center;
  width: 34%;
}

.scholar-testimonials {
  text-align: center;
  padding: 40px 0;
  .hero-textcenter-section {
    &.hero-textcenter p {
      padding: 15px 0;
    }
  }
}

.light-pink-bg {
  background: #ffeedf;
  padding: 10px 0rem !important;
}
