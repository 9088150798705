.errorBoundry{
    display: flex;
    flex-direction: column;
    height: 30vh;
    align-items: center;
    justify-content: center;
    img{
        height: 6rem;
        width: 6rem;
    }
}
