.blog-wrapper {
  width: 100%;
  .wp-block-buttons .blog-card-button{
    display: flex;
    height: 48px;
    padding: 8px 26px;
    justify-content: center;
    align-items: center;
    width: auto!important;
  }
  p {
    margin: 0;
  }

  h2 {
    margin-top: 20px !important;
  }

  .welcome-text {
    max-width: 1080px;
    margin: 0 auto;
    font-size: 24px;
    font-style: normal;
    font-weight: 420;
    line-height: 140%;
    color: #222;
  }

  .blog-content-wrapper {
    max-width: 1080px;
    margin: 0 auto;

    .blog-content {
      &__item {
        padding: 40px 0px 0px 0px;

        .see-all-stories {
          padding: unset;
        }
      }
    }
  }

  .load-more-story {
    margin: 0px auto 40px auto;
  }

  .category-container {
    max-width: 1080px;
    margin: 0 auto;
  }

}

@media screen and (max-width: 1200px) {
  .blog-wrapper .blog-content-wrapper {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

@media screen and (max-width: 991px) {
  .blog-wrapper {
    .light-pink-bg {
      padding: 40px 10px !important;
    }
  }

  .story-wrapper {
    margin-bottom: 30px;

    h3 {
      margin-top: 24px !important;
      font-size: 24px !important;
    }

    .story__content {
      font-size: 20px;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 767px) {
  .view-load-more {
    height: 40px;
    padding: 8px 12px;
    font-size: 14px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 640px) {
  .blog-wrapper {
    .blog-content-wrapper {
      .blog-content__item {
        .see-all-stories {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          gap: 15px;

          .stories-badge-wrapper {
            .single-story-badge {
              margin: 0px 15px 0px 0px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .category-container {
    .item-title {
      padding-left: 1rem !important;
    }
  }

  .story-wrapper h3 {
    margin-top: 10px !important;
    font-size: 13px !important;
  }

  .blog-wrapper {
    .blog-content-wrapper {
      .blog-content__item {
        .see-all-stories {
          .stories-badge-wrapper {
            flex-wrap: wrap;
            gap: 15px;
            grid-column: 1/-1 !important;

            .single-story-badge {
              margin: 0px 0px 0px 0px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .light-pink-bg {
    padding: 40px 20px !important;
  }
}
