.photo-carousel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1080px;
  margin: 40px auto 60px;
  padding: 0 20px;
}
.slider-container {
  max-width: 1080px;
}

.slick-slide {
text-align: center;
position: relative;
transition: transform 0.5s ease-in-out;
}
.slick-slide:focus {
outline: none;
}
.slick-slide-title {
text-transform: capitalize;
}
.slick-slide-image {
max-width: 100%;
height: auto;
border-radius: 8px;
box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
  0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
color: #fff;
padding: 10px;
position: absolute;
left: 0px;
font-size: 1.5em;
bottom: 0px;
width: 100%;
}
.slick-prev:before,
.slick-next:before {
color: #777777;
}
.slick-slide-image {
border: none !important;
}

.slick-slide img {
display: inline !important;
width: 984px;
height: 550px;
background-size: cover;
object-fit: cover;
}

.custom-thumbnail-slider .slick-slide {
  margin-left: 0px;
 }

.custom-thumbnail-slider {
transition: none !important;
}

.slick-dots {
position: relative !important;
  margin-top: 25px;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  min-width: 1040px;
}
.slick-dots li {
  width: auto;
  margin: 0 15px 0 0;
  display: flex;
  height: auto;
}

.slick-dots li img {
background-size: cover;
}

// .custom-thumbnail-slider img {
//   outline: none !important;
// }

// .custom-thumbnail-slider .slick-current img {
// outline: 5px solid #FAA946;
// outline-offset: -5px;
// }

.slick-track .slick-active img {
border-radius: 0;
box-shadow: none;
}

.custom-thumbnail-slider img {
display: inline !important;
// width: 100% !important;
// height: auto !important;
border-radius: 0 !important;
}

.slick-thumb {
display: flex !important;
justify-content: space-evenly !important;
left: -30px;
}

.custom-thumbnail-slider .slick-arrow,
.custom-thumbnail-slider .slick-dots  {
display: none !important;
}

.slick-next:before, .slick-prev::before {
display: none;
}

.text-overlay {
position: absolute;
bottom: 0;
left: 0;
width: 100%;
background: rgba(128, 128, 128, 0.8); /* Grayish background */
color: white;
padding: 15px;
font-size: 16px;
text-align: left;
}

.text-image-container {
position: relative;
max-width: fit-content;
font-size: 24px;
font-weight: 420 !important;
}

.text-overlay p {
margin: 5px 0;
}
.text-overlay strong {
font-weight: 500;
}

.slid-image-desc {
margin: 20px 0px;
align-items: flex-start;
display: flex;
justify-content: space-between;
align-items: anchor-center;
font-size: 14px;
font-weight: 420px;
color: #222222;
}
.img-name, .img-song {
font-weight: 500 !important;
}

.slick-active > div {
display: flex;
flex-direction: column;
}

.end-text {
text-align: right;
}

.custom-main-slider {
width: 984px;
margin: 0 auto;
}
.svg-icon {
  fill: #000;
  width: 24px;
  height: 24px;
}
.svg-nav-icon {
fill: #005596;
}

.slick-prev {
left: -34px;
z-index: 10;
}
.slick-next {
right: -30px;
}
.content-section-col p {
font-size: 18px !important;
font-weight: 600;
}
.content-section-col {
margin-top: 0;
margin-bottom: 20px;
}

/*
@media (min-width: 1440px) {
  body {
    background-color: aliceblue !important;
  }
  .custom-thumbnail-slider .slick-slide {
    margin: 0 12px;
  }
}
 */
.custom-thumbnail-slider .slick-current img:focus,
.custom-thumbnail-slider .slick-current img:focus-visible {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
*:focus,
*:focus-visible {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.custom-thumbnail-slider .slick-current img {
  outline: 5px solid #FAA946 !important;
  outline-offset: -5px;
}
@media (min-width: 1025px) {
.custom-thumbnail-slider {
  max-height: 120px;
  overflow: hidden;
}
}
@media (max-width: 1024px) {
.custom-main-slider {
  width: 880px;
}
.slick-slide img {
  height: 474px;
}
.custom-thumbnail-slider img {
  width: 97% !important;
  height: 120px !important;
  object-fit: cover;
  background-size: cover;
}
.custom-thumbnail-slider {
  max-height: 120px;
  overflow: hidden;
}
// .custom-thumbnail-slider .slick-slide .slick-active {
//   margin: 0 5px;
// }
}

@media (width: 768px) {
  .custom-thumbnail-slider .slick-slide {
    margin-left: 0 !important;
  }
  .custom-main-slider {
    width: 720px;
  }
  .custom-thumbnail-slider {
    max-height: 120px;
    width: 720px;
    overflow: hidden;
  }
  .custom-thumbnail-slider img {
    width: 200px !important;
    height: 120px !important;
    object-fit: cover;
    background-size: cover;
  }
  .custom-thumbnail-slider .slick-slide {
    margin: 0 0px;
  }
  .custom-thumbnail-slider .slick-current img {
    outline: 3px solid #FAA946 !important;
    outline-offset: -3px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
.slider-container {
  width: 100%;
  margin: 0 auto;
}
.custom-main-slider {
  width: 90%;
  margin: 0 auto;
}
.custom-main-slider {
  width: 624px;
}
.slick-slide img {
  height: 349px;
}
// .custom-thumbnail-slider img {
//   width: 98% !important;
// }
.custom-thumbnail-slider {
  max-height: 120px;
  overflow: hidden;
  margin: 0 auto;
}
}

@media (max-width: 479px) {
.slider-container .slick-prev {
  left: 15px;
  top: 35%;
}

.slider-container .slick-next {
  right: 20px;
  top: 35%;
}

.text-overlay {
  padding: 5px 15px;

}

.text-overlay p {
  margin: 2px 0;
}

.text-overlay p strong,
.text-overlay p b {
  font-size: 16px;
}

.custom-main-slide {
  width: 100%;
  margin: 0 auto;
}
.start-text {
  text-align: left;
  width: 82%;
}
.svg-nav-icon {
  fill: #FFF;
}
.custom-main-slider {
  width: 100%;
}
.slider-container .slick-prev,
.slider-container .slick-next {
  top: 34%;
}
.custom-main-slider img {
  height: 202px;
  object-fit: cover;
}
.svg-nav-icon {
  fill: #FFF;
}
.custom-thumbnail-slider img {
  width: 99% !important;
  height: 110px !important;
}
}

@media (max-width: 414px) {
  .custom-thumbnail-slider img {
    width: 139px !important;
    height: 84px !important;
  }
  .custom-thumbnail-slider .slick-current img {
    outline: 3px solid #FAA946 !important;
    outline-offset: -3px;
  }
}

@media (max-width: 390px) {
.custom-main-slider img {
  height: 202px;
  width: 358px;
}
.svg-nav-icon {
  fill: #FFF;
}
.slider-container .slick-prev,
.slider-container .slick-next {
  top: 34%;
}

.custom-thumbnail-slider {
  margin: 0 -10px;
}

.custom-thumbnail-slider img {
  width: 139px !important;
  height: 84px !important;
}

.custom-thumbnail-slider .slick-current img {
  border: none !important;
}

.custom-thumbnail-slider img {
  width: 139px !important;
  height: 84px !important;
}

.custom-thumbnail-slider {
  max-height: 90px;
  overflow: hidden;
}
.custom-thumbnail-slider .slick-current img {
  outline: 3px solid #FAA946 !important;
  outline-offset: -3px;
}
}
h2.wp-block-heading.alignfull.has-text-align-center {
margin-top: 60px;
color: #161C46 !important;
}
.wp-block-column p.has-text-align-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1080px;
  margin: 40px auto 60px;
  padding: 0 20px;
  font-size: 18px !important;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  color: #222222;
}
.talent-carousel-container, .has-text-align-center {
text-align: center;
}

.svg-nav-icon path {
transition: fill 0.1s ease;
}

@media (hover: hover) {
  .svg-nav-icon:hover path {
    fill: #FAA946;
  }  
}

.svg-nav-icon path {
will-change: fill;
transition: fill 0.1s ;
}