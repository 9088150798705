// .owl-nav {
//   display: flex;
//   justify-content: space-between;
//   position: absolute;
//   width: 100%;
//   top: 43%;
// }

// .owl-carousel .owl-nav button.owl-prev,
// .owl-carousel .owl-nav button.owl-next,
// .owl-carousel button.owl-dot {
//   background: none;
//   color: inherit;
//   border: none;
//   padding: 0 !important;
//   font: 60px !important;
//   font-size: 60px;
// }

// .owl-theme .owl-dots {
//   margin-top: 40px;
//   margin-bottom: 40px;
// }

// .owl-theme .owl-dots .owl-dot.active span,
// .owl-theme .owl-dots .owl-dot:hover span {
//   background: #005596;
// }

// .owl-theme .owl-nav [class*='owl-']:hover {
//   background: transparent;
//   color: #000;
// }

// .owl-carousel .owl-nav button.owl-prev {
//   position: relative;
//   right: 34px;
// }

// .owl-carousel .owl-nav button.owl-next {
//   right: -40px !important;
//   position: relative;
// }

// .carousel {
//   max-width: 1080px;
//   margin: 0 auto;
// }

// .owl-carousel .owl-item {
//   .carousel-card {
//     min-width: unset;
//     max-width: unset;
//     color: black;
//     text-align: center;
//     justify-content: center;
//   }
// }

// .owl-theme .owl-dots .owl-dot span {
//   margin-right: 15px;
// }

// // .owl-stage {
// //   transform: none !important;
// // }

// @media only screen and (max-width: 480px) {
//   .owl-nav {
//     display: none;
//   }
// }
.blog-img {
    object-fit: cover;
}