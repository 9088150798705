.tickerBanner {
  display: flex;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
}
.tickerBanner {
  img {
    width: 100%;
    max-width: 100%;
  }
}
.ticketBannerText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.ticketBannerText h4 {
  color: #fff;
  font-weight: 600 !important;
  font-family: Brandon Grotesque !important;
  font-size: 5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ticketBannerText::after p:empty {
  display: none !important;
}

@media screen and (min-width: 1440px) {
  .tickerBanner img {
    width: 100%;
    max-width: 100%;
   }
}

@media screen and (max-width: 1200px) {
  .ticketBannerText h4 {
    font-size: 2.8rem !important;
  }
}

@media screen and (max-width: 1090px) {
  .ticketBannerText h4 {
    font-size: 2.8rem !important;
  }
}
@media screen and (max-width: 991px) {
  .ticketBannerText h4 {
    font-size: 36px !important;
  }
}

@media screen and (max-width: 800px) {
  .ticketBannerText h4 {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 680px) {
  .ticketBannerText h4 {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 580px) {
  .ticketBannerText h4 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 480px) {
  .ticketBannerText h4 {
    font-size: 14px !important;
  }
}
