.pagenotfound{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    font-size: 2rem;
    img{
        height: 7rem;
        width: 7rem;
        margin-bottom: 1rem;
    }
}