@import '../../../style/_variables.scss';
@import '../../../style/_mixins.scss';

a,
button,
.btn {
  transition: all ease-in-out 0.3s;
}

.blue-bg {
  background: $blue-bg;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.mobile-arrow__open {
  transform: rotate(180deg);
}

header {
  nav {
    &.navbar {
      padding: 14px 0;
      height: auto;

      .navbar-brand {
        background: transparent !important;
        border: none;
      }

      ul {
        margin: 0 50px;
        height: auto !important;
        --bs-scroll-height: auto !important;

        li {
          .navActive {
            color: $color-orange !important;
            background: none;
          }

          button {
            font-size: 16px;
            color: $color-white;
            text-transform: uppercase;
            font-weight: 500;
            background: transparent;
            border: none;

            &.nav-link {
              &.dropdown-toggle {
                &::after {
                  display: none;
                }
              }
            }

            &.active {
              color: $color-orange !important;
            }

            &.nav-link {
              margin: 0 5px;
              font-family: Brandon Grotesque Bold !important;
            }

            &:hover {
              color: $color-orange !important;
              background: none;
            }

            &:focus {
              color: $color-orange !important;
              background: none;
            }
          }

          &.nav-item {
            display: flex;
            align-items: center;
            position: static;

            img {
              display: none;
            }

            &.dropdown {
              ul {
                background: #2e3a7a;
                width: 100%;
                display: none;
                left: 0;
                right: 0;
                margin: 0 auto;
                max-width: 100%;
                top: 101px;
                border-radius: 0;
                border: 0;
                text-align: center;
                justify-content: center;

                &.show {
                  display: flex;
                  height: 70px !important;
                  align-items: center;

                  li {
                    padding: 0 15px;

                    button {
                      &:hover {
                        border-bottom: 1px solid $color-orange;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .header-button {
      display: flex;
      align-items: center;
      gap: 15px;

      button.btn {
        border-radius: 0;
        width: 125px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;

        &.btn-orange {
          background: $color-orange;
          color: $text-color;
        }

        &.btn-blue {
          background: $color-blue;
          color: $color-white;
        }
      }
    }
  }

  .navbar-collapse {
    justify-content: center;
  }

  .navbar-toggler {
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    color: $color-white !important;
    background-color: transparent;
    border: none;
    border-radius: 0;
    transition: none;

    &-icon {
      background-size: contain;

      img {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.content--navbar {
  position: relative !important;
  top: 0 !important;
}

.header-contact-form {
  display: none;
}

// new media

/*media query*/

@media screen and (max-width: 1280px) {
  header {
    nav {
      &.navbar {
        ul {
          margin: 0 40px;

          li {
            a.nav-link {
              margin: 0 4px;
            }
          }
        }
      }

      .header-button {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 0;
      }
    }
  }
}

.blue-strip-active-menu {
  overflow: hidden;

  .dropdown-menus {
    &::before {
      position: absolute;
      left: -15px;
      background: #2e3a7a;
      content: '';
      top: 0;
      width: 60px;
      height: 100%;
      filter: blur(10px);
      display: none !important;
    }

    &::after {
      position: absolute;
      right: -15px;
      background: #005596;
      content: '';
      top: 0;
      width: 60px;
      height: 100%;
      filter: blur(10px);
      display: none !important;
    }

    background: #005596 !important;
    height: 61px !important;
    display: flex !important;
    align-items: center;
    position: relative !important;
    display: flex !important;

    ul {
      justify-content: unset !important;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 30px;
    flex-direction: row !important;
    justify-content: unset !important;
    li {
      white-space: nowrap;
      padding: 0 25px !important;

      a {
        color: #fff !important;
        text-decoration: none;

        &.active {
          border-bottom: 3px solid $color-orange;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0;
  }

  scrollbar-width: none;
  /* Firefox */
}

.blue-strip-active-menu {
  ul {
    justify-content: center !important;
  }
}

@media screen and (max-width: 1400px) {
  header .container {
    max-width: 1366px;
  }
}
@media screen and (max-width: 1200px) {
  .header-button {
    position: absolute;
    top: 30px !important;
  }
  .blue-strip-active-menu {
    ul {
      justify-content: unset !important;
    }
  }

  header {
    .navbar {
      width: 100% !important;
      max-width: unset !important;

      .container {
        width: 100% !important;
        max-width: unset !important;
      }
    }

    nav {
      &.navbar {
        padding: 20px 24px;
        height: auto;
        position: relative;

        ul {
          margin: 0 0px !important;
          flex-direction: column !important;
          width: 100%;
          margin-top: 0 !important;

          li {
            a {
              color: var(--bs-navbar-active-color) !important;
            }

            &.nav-item {
              align-items: center;
              justify-content: space-between;
              padding: 10px 15px;
              // border-bottom: 1px solid #696969;
              width: 100%;
              white-space: nowrap;

              button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                color: #000 !important;
              }

              img {
                display: block;
                height: 20px;
                width: 20px;
              }

              &.dropdown {
                ul {
                  &.show {
                    background-color: #ffeddf !important;

                    height: auto !important;
                    align-items: flex-start;
                    background: none;
                    flex-direction: column;
                    padding: 10px 10px 0;
                    line-height: 40px;

                    li {
                      padding: 0 25px 0 0.5rem;

                      a {
                        &:hover {
                          border: 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            a {
              &.nav-link {
                font-weight: 600;

                &:hover {
                  color: var(--bs-navbar-active-color) !important;
                }

                &.dropdown-toggle {
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;

                  img {
                    &.mobile-arrow {
                      width: 20px;
                      transform: rotate(0deg);
                    }
                  }

                  &.show {
                    color: var(--bs-navbar-active-color) !important;
                    font-weight: 600;

                    img {
                      &.mobile-arrow {
                        width: 20px;
                        transform: rotate(-180deg);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .navbar-collapse {
        background: #ffeddf;
        z-index: 9;
        position: absolute;
        top: 113px;
        width: 100%;
        left: 0;
        flex-direction: column;
      }
    }

    .navbar-toggler {
      position: absolute;
      top: auto;
    }

    .header-button {
      position: absolute;
      right: 20px;
      top: -149px;

      .btn-orange {
        display: none !important;
      }
    }

    .header-contact-form {
      background: #fff;
      display: flex !important;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 40px 0;
      gap: 10px;
      box-shadow: -2px 2px 6px 0px rgba(173, 173, 173, 0.2784313725);
      width: 100%;

      .signup-section {
        display: flex;
        position: relative;
        width: 270px;

        input {
          height: 40px;
          width: 270px;
          padding: 0 80px 0 8px;
        }

        a.btn.btn-orange {
          position: absolute;
          right: 5px;
          width: auto;
          height: 30px;
          min-width: unset;
          top: 5px;
          z-index: 1;
          background: #faa946;
          color: #222222;
          cursor: pointer;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto !important;
        }
      }
    }
  }

  // .nav--dropdown .nav-item.dropdown ul.show {
  //   justify-content: unset !important;
  // }

  header nav .header-button button.btn {
    width: 108px !important;
    height: 40px !important;
  }

  header {
    nav {
      &.navbar {
        padding: 10px 15px;
        height: 90px;
      }
    }

    .navbar-toggler {
      position: absolute;
    }

    .header-button {
      position: absolute;

      .btn-orange {
        display: none !important;
      }
    }
  }

  body .navBar__item {
    border-bottom: 1px solid #696969;

    .blue-strip-active-menu {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      ul {
        padding-top: 0 !important;
        margin-bottom: 4px !important;
        margin-top: 0 !important;
      }
    }

    .dropdown-menus {
      align-items: flex-start !important;

      li {
        button {
          color: #000;
        }
      }

      &::after {
        background: transparent !important;
      }

      &::before {
        background: transparent !important;
      }
    }
  }

  header nav .navbar-collapse {
    top: 160px !important;
    box-shadow: 0px 2px 12px #ddd;
  }

  header nav.navbar .navbar-brand {
    /* width: 100%; */
    position: relative;
    margin: 0 auto;
  }

  .blue-strip-active-menu {
    overflow: hidden;

    .dropdown-menus {
      &::before {
        position: absolute;
        left: -15px;
        background: #005596;
        content: '';
        top: 0;
        width: 60px;
        height: 100%;
        filter: blur(10px);
        display: none !important;
      }

      &::after {
        position: absolute;
        right: -15px;
        background: #005596;
        content: '';
        top: 0;
        width: 60px;
        height: 100%;
        filter: blur(10px);
        display: none !important;
      }

      background: #005596 !important;
      height: 61px !important;
      display: flex !important;
      align-items: center;
      position: relative !important;
      display: flex !important;

      ul {
        justify-content: unset !important;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      padding: 0 30px;
      flex-direction: row !important;

      li {
        white-space: nowrap;
        padding: 0 15px;

        a {
          color: #fff !important;
          text-decoration: none;

          &.active {
            border-bottom: 3px solid $color-orange;
          }
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0;
    }

    scrollbar-width: none;
    /* Firefox */
  }

  // header nav .navbar-collapse {
  //   top: 90px !important;
  // }
}

@media screen and (max-width: 991px) {
  header nav .navbar-collapse {
    // background: #161c46;
    z-index: 9;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding: 0;
  }

  .nav--dropdown {
    .nav-item {
      button {
        font-size: 14px !important;
      }

      &.dropdown {
        ul {
          &.show {
            li {
              padding: 0 0px;
            }
          }
        }
      }
    }
  }

  .header-contact-form {
    h5 {
      font-size: 28px;
      font-weight: 900;
      font-family: Brandon Grotesque bold !important;
    }
  }
}

@media screen and (max-width: 580px) {
  body header nav .header-button button.btn {
    width: auto !important;
    height: 37px !important;
    padding: 0 15px !important;
    min-width: auto;
  }

  header {
    .navbar-brand {
      img {
        height: 35px;
      }
    }

    nav {
      &.navbar {
        padding: 20px 0px;
      }

      .navbar-collapse {
        top: 100px;
      }
    }

    .header-button {
      right: 0;
      top: auto;

      a.btn {
        width: 100% !important;
        height: 40px !important;
        font-size: 14px !important;
        min-width: unset;
      }
    }

    .navbar-toggler {
      top: 32px;
    }
  }

  header nav .header-button {
    top: 25px !important;
    margin-right: 15px;
  }

  header nav.navbar .navbar-brand {
    /* width: 100%; */
    position: relative;
    // margin: 0 96px;
    img {
      transition: all ease 0.2s;
    }
  }
  // .blue-strip-active-menu {
  //   display: none !important;
  // }
}
