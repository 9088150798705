.view-load-more {
  display: flex;
  height: 48px;
  padding: 8px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  background: #faa946;
  font-family: Brandon Grotesque bold !important;
  text-transform: uppercase;
}
