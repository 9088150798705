.header-button {
    .btn {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        line-height: 18.4px;
        gap: 0px;
        .sub-desc {
            font-size: 10px;
        }
    }
}